import { graphql, navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

const Share = ({ data, pageContext }) => {
  useEffect(() => {
    navigate('/')
  }, [])
  return (
    <>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <meta name="og:url" content={`${data.site.siteMetadata.siteUrl}${pageContext.pagePath}`} />
        <meta name="og:image" content={`${data.site.siteMetadata.siteUrl}/image${pageContext.pagePath}.png`} />
        <meta
          name="description"
          content={data.site.siteMetadata.description}
        />
      </Helmet>
    </>
  )
}

export default Share

export const pageQuery = graphql`
  query ShareQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
